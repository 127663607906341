
import { graphql, StaticQuery } from 'gatsby'
import icon from '../../../images/icons/mystery-icon-white.svg'
import React from 'react'
import "./insta.sass"

export default () => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "insta-links"}}) {
          html
        }
      }
    `}
    render={data => {
      const content = data.markdownRemark.html;
      return (
        <div className="insta-links-container">
          <div className="insta-links-content">
            <div className="logo-container"><img src={icon}/></div>
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
          </div>
        </div>
      )
    }}
  />
)